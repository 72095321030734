<template>
  <div id="signup-page" class="pt-9">
    <app-box width="small">
      <template #legend>Sign up for an account</template>
      <transition name="fade">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <app-form-alert v-if="form.error" type="error" v-html="form.error" />
      </transition>
      <app-form @submit.prevent="submitForm">
        <app-form-field>
          <app-label id="name">
            Your name
          </app-label>
          <app-input id="name" v-model.trim="form.fields.name" autocomplete="name" />
        </app-form-field>
        <app-form-field>
          <app-label id="email">
            Your email address
          </app-label>
          <app-input id="email" v-model.trim="form.fields.email" autocomplete="email" />
        </app-form-field>
        <app-form-field>
          <app-label id="password">
            Choose a password
          </app-label>
          <app-input id="password" v-model="form.fields.password" type="password" autocomplete="new-password" />
        </app-form-field>
        <app-button class="mt-3 transition-colors" :disabled="loading || requiredFieldIsBlank">
          Sign up
        </app-button>
      </app-form>
      <hr class="mt-8 mb-2 border-gray-300">
      <p class="text-xs text-gray-600">
        Already have an account?<br>
        <router-link to="/login" class="text-blue-600 underline hover:no-underline">
          Click here to log in to it.
        </router-link>
      </p>
    </app-box>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Util as U } from "@/util";
export default {
  name: "Signup",
  data: () => ({
    form: {
      fields: {
        email: "",
        name: "",
        password: "",
      },
      error: "",
    },
  }),
  computed: {
    ...mapState(["loading"]),
    requiredFieldIsBlank() {
      const { email, name, password } = this.form.fields;
      return email === "" || name === "" || password === "";
    },
  },
  methods: {
    async submitForm() {
      const { email, password } = this.form.fields;
      if (email !== "" && !email.includes("@")) {
        this.form.error = `Oops, something went wrong.<br><span class="font-normal">The email address is not formatted correctly.</span>`;
      } else if (password !== "" && password.length < 6) {
        this.form.error = `Oops, something went wrong.<br><span class="font-normal">The password needs to be at least 6 characters long.</span>`;
      } else {
        const body = { account: { ...this.form.fields, uuid: U.uuid() } };
        const url = "api/accounts";
        const error = await U.api({ url, method: "post", body });
        if (typeof error !== "object") {
          this.form.error = `Oops, something went wrong.<br><span class="font-normal">${error}</span>`;
        } else {
          if (await U.getToken({ email, password })) {
            this.$store.dispatch("initAccount");
            this.$router.push("/projects");
          } else {
            this.form.error = `Oops, something went wrong.<br><span class="font-normal">We could not create an account at this time, please try again later.</span>`;
          }
        }
      }
    },
  },
};
</script>
